.scheduleContainer {
  display: grid;
  width:100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(fit-content, minmax(1.5fr, 15rem));
  gap: 20px;
  padding: 20px;
}

.printerTimeCard {
  cursor:default;
  background: white;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0,0,0,0.12);
  padding: 20px;
  padding-top:15px;
  display:flex;
  flex-direction:column;
  justify-content:space-around;
}

.printerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.availabilityStatus {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9em;
  transition: 0.3s opacity ease-in-out;
}

.show{
  opacity:1;
}

.hidden{
  opacity:0;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition:background-color .5s ease-in-out;
}

.currentTimeIndicator {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background:rgb(0, 0, 0);
  z-index: 2;
}

.indicator.available {
  background: #2ecc71;
  box-shadow: 0 0 8px rgba(46, 204, 113, 0.3);
}

.indicator.occupied {
  background: #e74c3c;
  box-shadow: 0 0 8px rgba(231, 76, 60, 0.3);
}

.timeline {
  position: relative;
  height: 40px;
  background: #f8f9fa;
  border-radius: 8px;
  margin: 15px 0;
  height:5rem;
}

.timeBlock {
  position: absolute;
  top:5%;
  height: 90%;
  background:#ff7247;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.75em;
  padding: 0 5px;
  overflow: hidden;
}

.timeTick {
  position: relative;
  display: inline-block; /* Ensures proper positioning of the pseudo-element */
  text-align:center;
}

.timeTick::after {
  content: "";
  position: absolute;
  bottom: 100%; /* Positions the line just above the element */
  left: 50%;
  transform: translateX(-50%);
  width: 1px;  /* Thickness of the line */
  height: 13px; /* Length of the line */
  background-color: #666; /* Change this to your desired color */
}

.timeLabel{
  overflow-x:hidden;

}

.timeLabels {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
  color: #666;
  margin-top: 8px;
}

.noReservations {
  height:100%;
  text-align: center;
  display:flex;
  align-items:center;
  justify-content:center;
  color: #666;
}

.dayNavigation h2{
  width:min-content;
}

.dayNavigation {
  display: flex;
  justify-content: start;
  width:100%;
  padding-left:15px;
  padding-right:15px;
  align-items: center;
  gap: 10px;
  margin: 25px 0;
}

.dayNavigation button{
  border-radius:50%;
  border-width:0;
  background-color:white;
  color:#2c3e50;
  font-size:15px;
  padding:0;
  width:25px;
  height:25px;
  display:flex;
  justify-content:center;
  align-items:center;
}

button:focus{
  outline:none;
}

.dayNavigation button:disabled {
  color: #bdc3c7;
  cursor:default;
}

.dayNavigation button:hover:not(:disabled) {
  background:#ffbab8;
}

.dayNavigation h2 {
  margin: 0;
  color: #2c3e50;
  font-size: 1.4em;
}

@media (max-width: 768px) {
  .scheduleContainer {
    grid-template-columns: 1fr;
  }
  .timeLabels .timeTick {
    display: none;
  }
  .availabilityStatus span{
    display:none;
  }
  /* Only show selected tick labels */
  .timeLabels .timeTick:nth-child(1),
  .timeLabels .timeTick:nth-child(5),
  .timeLabels .timeTick:nth-child(9),
  .timeLabels .timeTick:nth-child(13) {
    display: inline-block;
  }
}