@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');

.first-visit-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.7s ease-in-out;
}

.popup-info{
  flex-direction:column;
  justify-content:center;
  align-items:center;
  flex-grow:1;
  display:flex;
  padding-bottom:16px;

}

.popup-content>text>i{
  border-radius:50%;
  opacity:1;
  width:min-content;
  font-size:1.3rem;
  color:black;
  transition:opacity .2s ease-in-out;
}

.popup-content>text{
  text-align:end;
}
.popup-content>text>i:hover{
  opacity:.5;
  cursor:pointer;
}

.popup-content {
  background: white;
  padding: 1rem;
  border-radius: 15px;
  text-align: center;
  max-width: 400px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
  min-width: 5rem;
  min-height:fit-content;
  display:flex;
  flex-direction:column;
  width: 50vw;
  height:25vh;
}

.popup-content h2 {
  margin-bottom: 1rem;
  font-weight:bold;
}

.popup-content p {
  color: #4a5568;
  margin-bottom: 1.5rem;
}

.popup-close-btn {
  background: #6366f1;
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.popup-close-btn>a{
  color:white;

}

.popup-close-btn:hover {
  background: #4f46e5;
  transform: translateY(-2px);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}