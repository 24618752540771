@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');

.join-us-main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  padding-top:0;
}

.join-us-header {
  padding: 3rem 2rem;
  border-radius: 1rem;
  color: white;
}

.join-us-header h4 {
  text-transform: uppercase;
}

.join-us-header h1 {
  margin: 0;
}

.join-us-section {
  margin: 4rem 0;
  padding: 2rem;
  border-radius: 1rem;
  background: #f8fafc;
}

.dark-bg {
  background: #1e293b;
  color: white;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  grid-auto-rows: 1fr;  /* Makes all rows equal height */

}

.grid-item {
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  flex-grow:1;
  flex-basis:1;
}
.grid-item>div{
  min-height:60%;
}

.grid-item:hover {
  transform: translateY(-5px);
}

@media (max-width: 768px) { 
  .grid-item p {
    display: none;
  }
}

.icon-list>li>i{
  display: flex;
  justify-content:center;
  width:1rem;
}

.icon-accent {
  font-size: 2.5rem;
  color: #6366f1;
  margin-bottom: 1rem;
}

.join-us-section:first-of-type{
  margin-top:0;
}



h3{
  font-size:1.5rem;
  font-weight:bold;
  color:black;
}

p{
  opacity:.9;
}

.icon-list {
  list-style: none;
  padding: 0;
  font-size: 1.1rem;
}

.icon-list li {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.benefit-card {
  flex: 1;
  min-width: 250px;
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;

}

.benefit-card:hover {
  transform: translateY(-5px);
}


.apply-section {
  text-align: center;
  background: linear-gradient(-45deg, #ffb8a3, #ff68a2, #23a6d5, #95f9e2);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  color: white;
}

.apply-section>h2{
  color:white;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.apply-button {
  display: inline-block;
  background: white;
  color: #4287ff;
  padding: 1rem 2rem;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  margin: 0rem 1rem 1rem 1rem;
  transition: all 0.3s ease;
  backface-visibility:hidden;
  will-change: transform;
}

.apply-button:hover {
  transform: scale(1.05) rotate(0.02deg);
  box-shadow: 0 4px 15px rgba(255,255,255,0.3);
}

@media (max-width: 768px) {
  .join-us-header h1 {
    font-size: 2rem;
  }
  .join-us-main{
    padding: 0 10px;
  }
  .grid-container {
    grid-template-columns: 1fr;
  }
}