/* 
HEX COLORS FROM FIGMA
red: #9d0000 
light grey: #f7f7ff
light blue: #BED6E9
regular blue: #587498
dark blue: #4a5866
*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

body,
html,
.App {
  background-color:white;
  margin: 0;
  width: 100%;
  min-height: 100vh;
  font-family: "Montserrat", "sans-serif", Monaco;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    flex-basis: 1;
}
h1, h2, h3{
  font-family:Monaco;
  color:#fc605a;
}
h4{
    font-family:"Montserrat","sans-serif";
    color:#587498;
    padding-bottom:0;
    margin-bottom:0;
    font-weight:bold;
    font-size:20px;
}
@font-face {
  font-family: "Monaco";
  src: local("Monaco"), url("./fonts/Monaco.ttf") format("truetype");
  font-weight: normal;
}
.genericContentContainer{
    align-self:center;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    padding-left:6%;
    padding-right:6%;
    padding-top:100px;
    padding-bottom:100px;
}
.rainbowText{
  background: radial-gradient(
    circle at 100%,
    #ff7979,  
    #ff5858 50%,  
    #ff947a 75%,  
    #ff7182 75%
  );
  font-weight: bold;
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animatedTextGradient 1.5s ease-in-out infinite;
  opacity:1;
  transition: opacity .2s ease-in-out;
}
.rainbowText:hover{
  animation: animatedTextGradient .9s ease-in-out infinite;
  opacity:.5;
}

@keyframes animatedTextGradient {
  to {
    background-position: 200% center;
  }
}
.fadeLoad{
    min-height:100%;
    flex-grow:1;
}
li{
  list-style:none;
}
ul{
  padding:0;
  margin:0;
}
.suggestion{
  position:fixed;
  z-index:9999;
  height:3rem;
  display:flex;
  align-items:center;
  padding-left:1rem;
  width:100vw;
  top: 80%;
  left:97.5%;
  border-radius:50px;
  border-style:solid;
  color:beige;
  border-color:#3e474f;
  background-color:#587498;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: ease-in-out transform .2s;
}
.suggestion:hover{
  transform:translateX(-20px);
  cursor:pointer;
}
.popupQr{
  position:fixed;
  top: 80%;
  left:97.5%;
  border-style:solid;
  border-color:black;
}
.cardGrid{
    display:grid;
    grid-template-columns:repeat(3, minmax(250px, 1fr) );
    grid-template-rows: auto;
    gap:20px;
    width:auto;
    padding-bottom:20px;
    margin-left:6%;
    margin-right:6%;
}
.cardGrid.twoByTwo{
    grid-template-columns:repeat(2,minmax(250px,1fr));
    gap:90px;
}
.padSpacing {
  margin-top: 100vh;
}
.sideBar {
  width: 7%;
  margin-top: 29px;
  text-align: right;
  border-right: solid 1px rgb(219, 108, 108);
}
.timeText {
  margin: 0px;
  padding: 0px;
  padding-right: 10px;
  font-size: 14px;
  border-top: solid 1px rgb(233, 208, 208);
}
.scheduleRowEmpty {
  border-bottom: solid 1px rgb(233, 208, 208);
  border-right: solid 1px #fc605a;
  margin: 0px;
  padding: 0px;
}
.calendarMain {
  width: 100%;
}
.calendarMain2 {
  width: 96%;
  margin: 20px 2%;
  display: flex;
  flex-direction: row;
}
/* STYLING SHIFTS */
.shiftOuterDiv {
  border-radius: 6px;
  position: absolute;
  z-index: 5;
  opacity: 0.9;
  width: 97%;
  margin-left: 1.5%;
  margin-right: 1.5%;
}
.shiftInnerDiv {
  width: 96%;
  height: 96%;
  margin: 2% 2%;
  overflow-x: scroll;
  text-align: center;
}
.shiftTextTitle {
  color: black;
  font-size: 10px;
  margin: 0px;
  padding: 0px;
}

.shiftTextTitleFullScreen {
  color: #fc605a;
  font-size: 15px;
  margin: 0px;
  padding: 0px;
}
.titleDiv {
  display:flex;
  z-index:0;
  align-items:center;
  justify-content:space-around;
  gap:50px;
}
.titleOutDiv{
    padding-top:10px;
    padding-bottom:40px;
    padding-right:60px;
    padding-left:60px;
    height:calc(100vh - 85px - 2rem);
    display:flex;
    flex-direction:column;
    justify-content:center;
}

.shiftTextSubTitleFullScreen {
  font-size: 12px;
  color: white;
  margin: 0px;
  padding: 0px;
}
.shiftTextSubTitle {
  font-size: 10px;
  margin: 0px;
  padding: 0px;
}
/* STYLING SHIFTS */
.scheduleMain {
  width: 93%;
  position: relative;
  overflow-x: scroll;
  white-space: nowrap;
}
.weekdayTitleText {
  height: 30px;
  margin: 0px;
  width: 100%;
  border-bottom: solid 1px rgb(219, 108, 108);
}
.dayColumn {
  display: inline-block;
  position: relative;
}
.dayColumnHeader {
  width: 100%;
  height: 100%;
  text-align: center;
}
.hover {
  transition: 0.6s all;
  cursor: pointer;
}

.hover:hover {
  opacity: 0.6;
}
/* FOOTER */
footer {
  text-align: left;
  display:flex;
  justify-content:space-between;
  background-color: #4a5866;
  width: 100%;
  color: white;
  padding: 45px 50px;
}
.footer-title {
  font-size: 30pt;
}
/* FOOTER */

/* GENERAL - IMAGES */
.imgRounded {
  border-radius: 8px;
}
.imgShadow {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.iconDiv {
  width: 8%;
  margin-right: 6%;
}
.iconDivR {
  width: 86%;
  display: flex;
  align-items: center;
}
.icons {
  width: 100%;
}
.circleImage {
  border-radius: 50%;
}
.paddingImageM {
  width: 80%;
  margin: 10%;
}

.aboutIllus {
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* GENERAL - IMAGES */

/* GENERAL - BUTTONS AND INPUT */
button {
  margin: 0px;
  padding: 0pc;
  /* width: 100%; */
  padding: 11px 20px;
  border: none;
  border-radius: 10px;
  font-size: 11px;
  font-weight: bold;
  color: white;
  transition: background-color .3s ease-in-out;
}

input {
  margin: 0px;
  padding: 0pc;

  padding: 11px 20px;
  border: 1px solid black;
  border-radius: 10px;
  font-size: 16px;
}
.searchInput {
  width: 100%;
}
.brightRedButton {
  background-color: #fc605a;
  padding: 2 rem;
}
.brightRedButton:hover {
  background-color: #f5231b;
}
.redButton {
  background-color: #a1514e;
}
.redButton:hover {
  background-color: #800000;
}
/* GENERAL - BUTTONS AND INPUT */

/* GENERAL - COlORING/STYLING TEXT */
.leftText {
  text-align: left;
}
.pageTopTitle {
  margin-top: 15px;
  margin-bottom: 0px;
}
.pageSubTitle {
  margin-top: 0px;
  margin-bottom: 20px;
  margin-left: -3px;
  font-size: 30px;
}
b {
  padding-bottom: 10px;
}
.montserrat {
  font-family: "Montserrat", sans-serif;
}
.redText {
  color: #9d0000;
}
.brightRedText {
  color: #fc605a;
}
.lightRedText {
  color: #a1514e;
}
.tanText {
  /* color:  #e6cbb3;; */
  color: #be9878;
}
.darkTanText {
  color: #be9878;
}
.ltGreyText {
  color: #f7f7ff;
}
.ltBlueText {
  color: #d3dce5;
}
.blackText {
  color: #000000;
}
.whiteText {
  color: #ffffff;
}
.regMaroonText {
  color: #800000;
}
.lightBlueText {
  color: #bed6e9;
}
.darkBlueText {
  color: #587498;
}
.darkerBlueText {
  color: #4a5866;
}
.underline {
  text-decoration: underline;
}
.textCenter {
  text-align: center;
}
.textAlignRight {
  text-align: right;
}
.bold {
  font-weight: bold;
}
.lText {
  font-size: 25px;
}
.xlText {
  font-size: 35px;
}
.xxlText {
  font-size: 45px;
}
.noMarginText {
  margin: 0px;
}
.topTextSpace {
  margin-top: 7px;
}
.leftTextSpace {
  margin-left: 5px;
}
/* GENERAL - COlORING/STYLING TEXT */

/* GENERAL - BORDER */
.sideBorderL {
  border-left: 2px solid #000000;
}
.sideBorderR {
  border-right: 2px solid #000000;
}

/* GENERAL - BORDER */

/* GENERAL - COlORING BACKGROUNDS fbeddf*/
.ltBlueBackground {
  background-color: #e6cbb3;
  width: 100%;
}

.ltTanBackground {
  background-color: #e6cbb3;
  width: 100%;
}
.ltGreyBackground {
  background-color: #f7f7ff;
  width: 100%;
}
.lterGreyBackground {
  background: #f2f2f2;
  width: 100%;
}
.dkGreyBackground {
  background-color: #f2f2f2;
  width: 100%;
}
.dkBlueBackground {
  background-color: #4a5867;
  width: 100%;
}

/* GENERAL - COlORING BACKGROUNDS */

/* GENERAL - LINKS */
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.whiteLink {
  color: #ffffff;
  text-decoration: none;
}
.whiteLink:hover {
  color: #fc605a;
  text-decoration: none;
}

.whiteLinkUnderline {
  color: #ffffff;
  border-bottom: 2px solid white;
  text-decoration: none;
}
.whiteLinkUnderline:hover {
  color: #fc605a;
  border-bottom: 2px solid #fc605a;
  text-decoration: none;
}

.blackLink {
  color: black;
  border-bottom: 2px solid #000000;
  text-decoration: none;
}
.blackLink:hover {
  color: #587498;
  text-decoration: none;
  border-bottom: 2px solid #587498;
}
.redLink {
  color: #800000;
  border-bottom: 2px solid #800000;
  text-decoration: none;
}
.redLink:hover {
  color: #c00e0e;
  text-decoration: none;
  border-bottom: 2px solid #c00e0e;
}

.lightRedLink {
  color: #a1514e;
  border-bottom: 2px solid #a1514e;
  text-decoration: none;
}
.lightRedLink:hover {
  color: #800000;
  text-decoration: none;
  border-bottom: 2px solid #800000;
}
.brightRedLink {
  color: #f1abab;
  border-bottom: 2px solid #f78282;
  text-decoration: none;
}
.brightRedLink:hover {
  color: #f5231b;
  text-decoration: none;
  border-bottom: 2px solid #f5231b;
}

.brightRedLinkNoUnderline {
  color: #fc605a;
  text-decoration: none;
}
.brightRedLinkNoUnderline:hover {
  color: #f5231b;
  text-decoration: none;
}
.blackLinkNoUnderline {
  color: black;
  text-decoration: none;
}
.blackLinkNoUnderline:hover {
  color: #587498;
  text-decoration: none;
}
.whiteLinkNoUnderline {
  color: white;
  text-decoration: none;
}
.whiteLinkNoUnderline:hover {
  color: #fc605a;
  text-decoration: none;
}

.lightRedLinkNoUnderline {
  color: #a1514e;
  text-decoration: none;
}
.lightRedLinkNoUnderline:hover {
  color: #800000;
  text-decoration: none;
}
.linkMarginBottom {
  margin-bottom: 20px;
}
.blueLink {
  color: #a1514e;
  border-bottom: 2px solid #a1514e;
  text-decoration: none;
}
.blueLink:hover {
  color: #5e0606;
  text-decoration: none;
  border-bottom: 2px solid #5e0606;
}

.blueArrowLink {
  text-decoration: none;
  border-bottom: none;
}
.blueArrowLink:hover {
  color: #587498;
  text-decoration: none;
  border-bottom: 2px solid #587498;
}

/* GENERAL - LINKS */

/* GENERAL - DIVS */
.chairParallax {
  height: 100%;
  width: 100%;
}
.parallaxContainer {
  position: relative;
  width: 100%;
  height: 100%;
}
.parallaxBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateZ(0px);
}

.foreground {
  margin-top: auto;
  margin-bottom: 50px;

  transform-origin: 0;
  transform: translateZ(3px) scale(0.625);
}

.parallaxBackgroundImage {
  width: 100%;
  position: relative;
}

.keyboardParallax {
  /* The image used */
  background-image: url("./images/keyboard2.jpg");
  /* height: 100%; */
  /* width:100%; */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: cover; */
  /* background-size: 50% 100%; */
  height: 300px;
}
.quadCard {
  width: 21%;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #ebd9ca;
}
.biCard {
  width:auto;
  min-height:300px;
  max-height:auto;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #4a5867;
  padding-bottom: 5%;
  /* overflow-y: hidden; */
  padding-right: 0px;
}
.cardIn {
  width: 86%;
  margin: 25px 7%;
}
.underNegativeMargin {
  margin-bottom: -5px;
}
.rowM {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.rowMH {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.rowWithMargin {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 88%;
  margin: 0 auto;
}

.rowWithMarginWrap {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 88%;
  margin: 0px auto;
}

.bi {
  width: 48%;
  display: flex;
  flex-direction: column;
}
.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.colNoCenter {
  display: flex;
  flex-direction: column;
}
.colWithMargin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 88%;
  margin: 0px 6%;
}
.colWithMarginReg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 88%;
  margin: 0px 6%;
}
.aboutColLeft {
  height: 100%;
  width: 42%;
  margin-right: 3%;
}
.aboutColRight {
  height: 100%;
  width: 47%;
  margin-left: 2%;
  margin-right: 6%;
}


.leftOneThirdCol {
  height: 100%;
  width: 37%;
  margin-right: 3%;
}
.rightTwoThirdCol {
  height: 100%;
  width: 57%;
  margin-left: 3%;
}
.tri {
  width: 31%;
  margin: 0px 1%;
}
.triL {
  width: 31%;
  margin-right: 2%;
}
.triR {
  width: 31%;
  margin-left: 2%;
}
.triWBorder {
  width: 36.3%;
  padding: 0px 8%;
}
.triLWBorder {
  width: 29.3%;
  padding-right: 8%;
}
.triRWBorder {
  width: 29.3%;
  padding-left: 8%;
}
.quad {
  width: 25%;
  padding: 0px 1%;
}

.quadAlt1 {
  width: 20%;
  margin-right: 3%;
}
.quadAlt2 {
  width: 23%;
  margin-right: 3%;
  padding-left: 3%;
}
.centerVertical {
  height: 100%;
  display: flex;
  align-items: center;
}
.centerHorizontal {
  display: flex;
  justify-content: center;
}
.width30percent {
  width: 30%;
  margin: 0px 35%;
}
.width40percent {
  width: 40%;
  margin: 0px 30%;
}
.width50percent {
  width: 50%;
  margin: 0px 25%;
}
.width60percent {
  width: 60%;
  margin: 0px 20%;
}
.width70percent {
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
}
.homeWidth70percent {
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
}
/* GENERAL - DIVS */

/* GENERAL - PADDING */
.verticalPaddingTiles {
  padding-top: 120px;
  padding-bottom: 3%;
}
.verticalPaddingS {
  padding: 40px 0px;
}
.verticalPaddingM {
  padding: 70px 0px;
}
.verticalPaddingL {
  padding: 100px 0px;
}
.verticalPaddingXL {
  padding: 120px 0px;
}
.verticalPaddingXXL {
  padding: 150px 0px;
}
.verticalPaddingTop {
  padding-top: 150px;
}
/* GENERAL - PADDING */

/* HEADER STYLING */

.learnmore {

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4vw;
  line-height: 22px;

  /* or 130% */
  letter-spacing: 0.01em;
  padding-left:14px;
}

.divider {
  width: 0vw;
  height: 25vw;
  right: 26vw;
  top: 32vh;
  background-color: purple;

  border: 0.124vw solid #000000;
}

.description {
  width: min-content;
  font-family: "Monaco";
  font-style: normal;
  font-weight: 400;
  font-size: 3vh;
  line-height: 1.1em;

  /* or 152% */
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: block;
  max-height: 100%; /* a x number of line to show (ex : 2 line)  */

  color: #000000;
}
.wanttotalk {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  /* or 148% */

  color: #587498;
}

canvas {
  height: 100vh;
  width: 100%;
  margin-top: -25px;
  position: relative;
  z-index: -1;
  pointer-events: auto;
}

.labname {
  display: inline;
  width: min-content;
  height: auto;
  /* background-color: lightpink; */
  cursor:default;
  user-select:none;
  font-family: "Monaco";
  font-style: normal;
  font-weight: 20vw;
  font-size: 6.9vw;
  line-height: 8.5vw;

  /* or 121% */
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #fc605a;
}

.contactus {
  display: inline;
  width: 10vw;
  height: 2vh;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 1.1vw;
  line-height: 10vh;

  /* or 148% */
  display: flex;
  align-items: center;
  letter-spacing: normal;
  text-decoration-line: underline;

  color: #000000;
}

.fb img {
  position: absolute;
  max-width: 100%;
  width: 2.5%;
  height: auto;
  left: 77.1vw;
  top: 70vh;
}

.insta img {
  position: absolute;
  width: 2.5%;
  height: auto;
  left: 79.8vw;
  top: 70vh;
  display: inline-block;
}

.twit img {
  position: absolute;
  width: 2.5%;
  height: auto;
  left: 82.5vw;
  top: 70.2vh;
  display: inline-block;
  object-fit: cover;
}

.atem img {
  position: absolute;
  width: 2.5%;
  height: auto;
  left: 84.9vw;
  top: 69.9vh;
  display: inline-block;
  object-fit: cover;
}

@media screen and (max-width: 850px) {
  .fb img {
    display: none;
  }

  .insta img {
    display: none;
  }

  .twit img {
    display: none;
  }

  .atem img {
    display: none;
  }
  .contactus {
    display: none;
  }
  .wanttotalk {
    display: none;
  }
  .description {
    width: 45vw;
    height: 27vw;
    /* background-color: lightpink; */

    font-family: "Monaco";
    font-style: normal;
    font-weight: 30vw;
    font-size: 2vh;
    line-height: 4vw;

    /* or 121% */
    display: flex;
    align-items: center;
    color: #000000;
  }
  .divider {
    display: none;
  }
  .labname {
    display: inline;
    width: 45vw;
    height: 50vh;
    user-select: none;
    cursor:default;
    font-family: "Monaco";
    font-style: normal;
    font-weight: 30vw;
    font-size: 8vw;
    line-height: 10vw;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;

    color: #fc605a;
  }

  .learnmore {
    width: 45vw;
    height: auto;
    font-size: 2vw;
  }
}

/* HEADER STYLING */

/* HOME PAGE STYLING */
h1{
    color:#fc605a;
    font-style:normal;
}
.homeInfo{
    border-left-color:black;
    border-left-width:2px;
    border-left-style:solid;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    padding-left:10px;
    gap: 10px;
}
.csilGlassImg {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
}

.margins{
  margin:50px;
}
.main {
  padding: 0px;
  min-height : 100%;
  flex-grow:1;
  padding-top: 25px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display:flex;
  align-items: center;
  flex-direction: column;
  animation:fade-in 1s ease-in-out;
}
@keyframes fade-in{
    0% {opacity:0;transform:translateY(2%)}
    100% {opacity: 1;transform:translateY(0%)}
}
.main::-webkit-scrollbar {
  display: none;
}

canvas {
  position: relative;
  width: 100%;
  height: 100%;
}
.uchicagoCol {
  height: 300px;
  /* width: 50%; */
  background-image: url("./images/uchicagow.png");
  background-attachment: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uchicagoImgContainer {
  width: 100%;
  position: relative;
}
.uchicagoImg {
  /* width: 100%; */
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  position: absolute;
}
.uchicagoImgText {
  font-size: 1.2em;
  position: relative;
}
.fullscreenVertical {
  height: 100vh;
  width: 100%;
}
.paddingBottom {
  padding-bottom: 90px;
}
.titleLandingText {
  font-size: 450%;
}

.leftLandingCol {
  height: 100%;
  width: 57%;
  margin-right: 3%;
}
.rightLandingCol {
  height: 100%;
  width: 20%;
  margin-left: 17%;
  margin-right: 3%;
}

.innerRightLandingCol {
  border-left: solid 2px black;
  padding-left: 40px;
}

.socialBarRow {
  gap:10px;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content:space-between;
  width: 100%;
  margin: 0 auto;
}

.socialIcon{
  color:black;
  font-size:1.9rem;
  transition:opacity .2s ease-in-out; 
}
.socialIcon:hover{
    opacity:.5;

}
.socialIcon.footer{
    color:white;
    font-size:20px;
}
.navBarSpacing{
    height:40px;
    width:100%;
}
/* HOME PAGE STYLING */

/* VISIT PAGE STYLING */
.visitImage {
  height: 250px;
}
.visitImageDiv {
  height: 100%;
}
.centerColVert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* VISIT PAGE STYLING */

/* STAFF PAGE STYLING */
.topStaffColL {
  width: 42%;
  margin-right: 3%;
}
.topStaffColR {
  width: 53%;
  margin-left: 2%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.staffIllGuyDiv {
  position: absolute;
  width: 50%;
  bottom: 0px;
}
.staffIllGuy {
  height: 100%;
}
.staffCosmos {
  width: 200px;
}

.staffCosmos:hover img.image-hover {
  opacity: 1;
}

.staffCosmos img.image-hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.2s;
}


.staffIllGuyDisplay {
  width: 100%;
  bottom: 0px;
}

.staffIllGuyDisplay:hover img.image-hover {
  opacity: 1;
}

.staffIllGuyDisplay:hover img.image-hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.2s;
}

.wrapColumns {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.staffDiv {
  width: 21%;
  margin: 20px 2%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.staffDiv img.image-hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.2s;
}
.staffDiv:hover img.image-hover {
  opacity: 1;
}

.staffPicture {
  width: 80%;
  margin: 10px 10%;
}
.staffText {
  font-size: 15px;
}
.staffEmailText {
  font-size: 14px;
}
/* STAFF PAGE STYLING */

/* MINICOURSE/EVENTS PAGE STYLING */
.minicourseHeader{
    font-size:100px;
}
.minicourseCard {
  width: 29.3%;
  margin: 20px 2%;
  min-height: 450px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.minicourseCardIn {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
  height: 100%;
  background: #bed6e9;
  border-radius: 10px;
  padding-bottom: 30px;
}

.minicourseCardImgDiv {
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  width: 80%;
  height:100%;
  margin: 0px 10%;
}
.cardImg {
  position: relative;
  width: 90%;
  margin: 0px 5%;
  margin-top: 35px;
  margin-bottom: 10px;
}

.cardIcon {
  filter: saturate(2.5);
  width: 20px;
  height: 20px;
}

.smText {
  font-size: 12px;
}
.cardRow {
  display: flex;
  flex-direction: row;
}
.leftCardSpace {
  margin-left: 15px;
}
.cardLinkDiv {
  transition: all 0.6s;
  cursor: pointer;
}
.cardLinkDiv:hover {
  opacity: 0.7;
}
/* MINICOURSE PAGE STYLING */

/* FOOTER PAGE STYLING */
.footerLeftDiv {
  width:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
}
.footerLeftDiv>a{
    text-decoration:underline;
    color:#fc605a;
    transition: opacity .1s ease-in-out
}
.footerLeftDiv>a:hover{
    opacity:.5;
}
.footerRightDiv {
  width: min-content;
  display: flex;
  min-height:100%;
  max-height:100%;
  flex-direction:column;
  justify-content:space-between;
}

/* FOOTER PAGE STYLING */

/* CONTACT PAGE STYLING */
.contactIll {
  width: 90%;
}
.contactMapTextDiv {
  display: flex;
  align-items: center;
  margin-left: 5%;
  width: 55%;
}
.contactButtonDiv {
  display: flex;
  justify-content: flex-end;
}
.contactButtonSubmit {
  transition: all 0.6s;
  cursor: pointer;
}
.contactButtonSubmit:hover {
  color: #d3dce5;
}
.contactStatus {
  transition: all 1s;
}
/* CONTACT PAGE STYLING */
.whiteBackgroundRound {
  background: white;
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
}
/* CONTACT PAGE STYLING */

/* CALENDAR PAGE STYLING */

.calendarRow {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.calendarTopBar {
  width: 30%;
  text-align: center;
}

.arrowDivLeft {
  width: 35%;
  text-align: right;
}
.arrowDivRight {
  width: 35%;
  text-align: left;
}
.arrowText {
  font-size: 20px;
  color: #be9878;
  cursor: pointer;
  transition: all 0.7;
}
.dateSelector {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}
.arrowText:hover {
  opacity: 0.7;
}
.calendarDisplay {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}
.labBar {
  height: 150px;
}
.labBarEvents {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.eventCard {
  width: 200px;
  height: 90px;

  background-color: #4a5867;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  margin-right: 23px;
  padding: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  flex: 0 0 auto;
}
.eventCard2 {
  width: 100%;
  background-color: #be9878;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  margin-right: 23px;
  padding: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  flex: 0 0 auto;
}
/* CALENDAR PAGE STYLING */

/* HARDWARE PAGE STYLING */

.hardwareInput {
  display: none;
}

.hardwareLabel {
  display: flex;
  width: 100%;
  height: 50px;
  cursor: pointer;
  border: 3px solid #3e474f;
  user-select: none;
}

.hardwareLabel div:first-child {
  width: 100%;
  line-height: 45px;
  margin-left: 10px;
  font-size: 1.2em;
}

.cross {
  margin-right: 15px;
  margin-top: 3px;
}

.cross:before,
.cross:after {
  content: "";
  border-top: 2px solid #3e474f;
  width: 15px;
  display: block;
  margin-top: 18px;
  transition: 0.3s;
}

.cross:after {
  transform: rotate(90deg);
  margin-top: -2px;
}

.hardwareTabContent {
  box-sizing: border-box;
  font-size: 0.9em;
  margin: 10px 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height, 0.5s;
}

.hardwareInput:checked ~ .hardwareTabContent {
  max-height: 400px;
  transition: max-height, 1s;
}

.hardwareInput:checked ~ .hardwareLabel .cross:before {
  transform: rotate(180deg);
}

.hardwareInput:checked ~ .hardwareLabel .cross:after {
  transform: rotate(0deg);
}

.tip {
  color: #f03768;
  cursor: help;
  position: relative;
  overflow: visible;
  font-family: monospace;
  font-size: 1.3em;
}

.tip:before,
.tip:after {
  position: absolute;
  opacity: 0;
  z-index: -100;
  transform: translateY(-30%);
  transition: 0.4s;
}

.tip:before {
  content: "";
  border-style: solid;
  border-width: 0.8em 0.5em 0 0.5em;
  border-color: #3e474f transparent transparent transparent;
  transform: translateY(-200%);
  bottom: 90%;
  left: 50%;
}

.tip:after {
  content: attr(data-tip);
  background: #3e474f;
  color: white;
  width: 150px;
  padding: 10px;
  font-size: 0.8em;
  bottom: 150%;
  left: -50%;
}

.tip:hover:before,
.tip:hover:after {
  opacity: 1;
  z-index: 100;
  transform: scaleY(1);
}

/* HARDWARE PAGE STYLING */

/* ABOUT PAGE STYLING */
.hardwareTile {
  width: 20%;
}
.scrollingRow {
  width: 100%;
}
.tileCenter {
  text-align: center;
}
.smallHardwareTile {
  width: 150px;
  text-align: center;
}
.hardwareImg {
  width: 100%;
}
/* ABOUT PAGE STYLING */

/* POPUP STYLING */
.innerPopupDiv {
  width: 60%;
  height: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 30px 25px;
  border-radius: 15px;
  opacity: 1;
}
/* POPUP STYLING */
@media screen and (max-width: 1200px) {
  .staffDiv {
    width: 29.3%;
    margin: 20px 2%;
    display: flex;
    flex-direction: column;
  }
  .minicourseCard {
    width: 46%;
    margin-left: 2%;
    margin-right: 2%;
  }
}
@media screen and (max-width: 900px) {
  .staffText {
    font-size: 15px;
  }
  .staffEmailText {
    font-size: 15px;
  }
  .staffDiv {
    width: 46%;
    margin: 20px 2%;
    display: flex;
    flex-direction: column;
  }
  .innerPopupDiv {
    width: 72%;
    height: 60%;
  }
}
@media screen and (max-width: 700px) {
  footer{
    flex-direction:column;
    align-items:center;
    gap:5px;
    padding-bottom:0px;
  }
  .innerPopupDiv {
    width: 92%;
    height: 92%;
  }
  .rowM,
  .rowMH,
  .rowWithMargin {
    flex-direction: column;
    width: 96%;
  }
  .rowWithMarginWrap {
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    margin: 0px 0px;
    height: auto;
  }
  .colWithMargin,
  .rightLandingCol,
  .leftLandingCol,
  .bi,
  .col,
  .width30percent,
  .quad,
  .colNoCenter,
  .quadAlt1,
  .quadAlt2,
  .footerLeftDiv,
  .footerRightDiv,
  .aboutColLeft,
  .aboutColRight,
  .topStaffColR,
  .topStaffColL,
  .minicourseCard,
  .calendarDisplay {
    width: 96%;
  }
  .quadMenu {
    width: 44%;
    margin: 10px 2%;
    text-align: center;
  }

  .margins{
    margin:auto;
  }
  .triL,
  .triR,
  .tri {
    width: 96%;
    margin: 0px 2%;
    align-items: center;
  }

  .biCard {
    width: 94%;
    margin: 20px 3%;
  }
  .titleLandingText {
    padding-top: 10%;
    font-size: 50px;
  }
  .footerRightDiv {
    justify-content: center;
  }
  .rowWithMargin > .colNoCenter {
    font-size: 10px;
  }

  .uchicagoCol {
    height: 250px;
  }
  .width70percent {
    width: 94%;
    margin-left: 2%;
    margin-right: 2%;
    padding: 1%;
  }
  .homeWidth70percent {
    width: 94%;
    margin-left: 2%;
    margin-right: 2%;
  }
  /* .pageTopTitle {
    margin-top: 105px;
  } */
  .pageSubTitle {
    font-size: 25px;
    text-align: center;
  }
  .aboutColLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .footerLeftDiv,
  .footerRightDiv {
    text-align: center;
  }
  .staffDiv {
    width: 96%;
    margin: 20px 2%;
    display: flex;
    flex-direction: column;
  }
  .arrowDivLeft {
    text-align: center;
  }
  .arrowDivRight {
    text-align: center;
  }
  .dateSelector {
    width: 96%;
  }
  .learnmore {
    top: calc(40vh + 10px);
  }
}

/* .landingCardsDiv {
  width: 94%;
  margin: 25px 3%;
  display: flex;
  flex-direction: row;
} */

/* Make front page responsive by displaying certain things only when its on computer */
@media screen and (max-width: 875px) {
  .learnmore,
  .wanttotalk,
  .contactus {
    display: none;
  }
  .socialBarRow{
    width:100%;
    flex-shrink:1;
    height:40px;
  }
  .cardGrid{
    grid-template-columns: repeat(auto-fit, minmax(250px,1fr))
  }
  .cardGrid.twoByTwo{
    grid-template-columns:repeat(auto-fit,minmax(250px,1fr));
    gap:10px;
  }
  .labname{
    height:auto;
    width:100%;
    border-bottom:2px;
    border-bottom-style:solid;
    border-bottom-color:black;
  }
  .socialIcon{
    font-size:20px;
  }
  .titleOutDiv{

    padding-bottom:0;
    padding-top:0;
    height:auto;
  }
  .genericContentContainer{
    flex-direction:column;
  }
  .homeInfo{
    border-left:none;
    width:100%;
  }
  .description{
    width:100%;
    font-size:1rem;
  }
  .titleDiv {
    display:block;
    padding-bottom: 0;
  }
 footer{
    justify-content:space-evenly;
 }
}
