nav{
    position:sticky;
    padding: 1rem 1.5rem;
    display:flex;
    justify-content:space-between;
    align-items:center;
    z-index:200;
}
.navMain {
  padding-top: 20px;
  transform-origin: top;
  padding-bottom:20px;
  max-width: 100%;
  min-width:100%;
  background-color: rgba(74, 88, 102, .9);
  position: absolute;
  top:0;
  padding-top:85px;
  margin: 0px;
  z-index: 20;
}
.visible{
    animation: pull-out .5s ease-in-out;

}
.navPopMain {
  display:grid;
  justify-content:center;
  grid-template-columns: 1fr 1fr 1fr;
  max-width:100vw;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  top:0;
  z-index: 100;
  height:fit-content;
}
/* Responsive styles */
@media (max-width: 1200px) { /* Medium screens */
  .navPopMain {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
    justify-items: center;

    align-items: center;
  }
}

@media (max-width: 768px) { /* Small screens */
  .navPopMain {
    grid-template-columns: 1fr; /* 1 column for small screens */
    justify-items: center;

    align-items: center;
  }
  .quadMenu:nth-last-child(1),
  .quadMenu:nth-last-child(2) {
    display: none;
  }
}
.navPopMain::-webkit-scrollbar {
  display: none;
}
.qrlImage{
  max-width:9.5rem;
  max-height:9.5rem;
  border-style:black;
  opacity:1;
}

.qrlMain{
  display:flex;
  gap:2rem;
}
.qrlMain>a>ul>li{
  margin-bottom:20px
}
@keyframes pull-out {
    from{ 
        transform:translateY(-100%);
    }
    to{
        transform:translateY(0);
    }
}
.quadMenu {
    width: max(min-content,.5rem);
    padding: 0px 1%;
  }
.quadMenu>h2{
    color:white;
    cursor:default;
    user-select:none;
}
.quadMenu>a>.whiteLinkNoUnderline{
    transition: color .1s ease-in-out;
}
.quadMenu>a>.whiteLinkNoUnderline:hover{
    color:#fc605a;
}
.hamDiv {
  position:relative;
  color: #4a5867;
  border-radius: 5px;
  z-index: 100;
}

.hamburger-react {
  z-index: 200;
}

.navIconDiv {
  width: 60px;
  z-index: 10;
  cursor: pointer;
}
.navIcon {
  width: 100%;
}
.navIcon:hover {
  opacity: 0.6;
}
@media screen and (max-width: 700px) {
  .navIconDiv {
    left: 5px;
  }
  .hamDiv {
    right: 5px;
  }
}

@media screen and (max-width: 875px) {
  .menuPop {
    height: 100vh;
  }
}
